/* App.css */

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  background-color: #232a3f;
  color: #ed1c25;
  padding: 20px 0;
}

main {
  flex: 1;
  padding: 20px;
}

footer {
  background-color: #232a3f;
  color: #ed1c25;
  padding: 10px 0;
}

.mainImage {
  cursor: pointer;
}

.additionalContent {
  background-color: #232a3f;
  color: #ed1c25;
  padding: 10px;
  margin: 10px;
}

/* Media Query for Mobile Devices */
@media only screen and (max-width: 600px) {
  main {
    padding: 10px;
  }

  .mainImage {
    width: 100%;
    height: auto;
  }
}

/* App.css */

.socialIcons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.socialIcon {
  width: 200px; /* Adjust the size as needed */
  margin: 0 10px; /* Adjust the spacing between icons */
}

.wideImage {
  max-width: 100%; /* Ensure the image doesn't exceed its container width */
  height: auto; /* Maintain aspect ratio */
  width: 50%; /* Adjust the width as per your requirement */
  margin: 0 auto; /* Center the image horizontally */
}
